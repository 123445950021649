import React, { useState, useEffect, useContext } from "react";
import NextLink from "next/link";
import { useRouter } from "next/router";
import { Image, Flex, Text, Box, Heading, Link, Stack } from "@chakra-ui/react";
import { LoadingSpinner, PageHead, ContentContainer, VideoCard } from "src/components";
import { UserContext } from "src/context/UserContext";
import { useAuth } from "src/hooks/api";
import * as Sentry from "@sentry/nextjs";

// Sanity
import { groq } from "next-sanity";
import client from "lib/client";

// Sanity fetch query
const query = groq`
*[_type in ['menu','landingPage','portalSettings']
 && _lang == $lang]{
  rtl,
  menuItems,
  logOutText,
  pageTitle,
  "mainImage": mainImage.asset->url,
  "mainImageCaption": mainImage.captionImage,
  getQualifiedText,
  "deviceImage": deviceImage.asset->url,
  "deviceImageCaption": deviceImage.captionImage,
  signUpText,
  loginButton,
  signupButton,
  siteTitle,
  aboutText,
  videoUrls[]{
    personName,
    "placeholderImage": placeholderImage.asset->url,
    url,
    videoDescription,
    videoTitle
  },
  companyUrl,
  "companyLogo": uploadCompanyLogo.asset->url,
  "companyLogoCaption": uploadCompanyLogo.caption,
  "titleImage": brandTitleImage.asset->url,
  "titleCaption": brandTitleImage.caption,
  "footerLogo": uploadFooterLogo.asset->url,
  "footerCaption": uploadFooterLogo.caption
}
`;

export default function Home() {
  const [data, setData] = useState([]);
  const { language } = useContext(UserContext);
  const router = useRouter();
  const { isAuthenticated } = useAuth();

  useEffect(() => {
    if (isAuthenticated) {
      router.push(`/courses`, `/courses`, { locale: language });
    }
    return;
  }, [isAuthenticated]);

  useEffect(() => {
    if (language === "") {
      router.push("/selectlanguage");
    }
  }, [language, router]);

  // fetching sanity
  useEffect(() => {
    client
      .fetch(query, { lang: language })
      .then((res) => setData(res))
      .catch(console.error);
  }, [language]);

  // removing null properties
  data.forEach((obj) => {
    Object.keys(obj).forEach((k) => obj[k] == null && delete obj[k]);
  });

  // merge obects from sanity, destructure values
  const {
    rtl,
    pageTitle,
    siteTitle,
    mainImage,
    mainImageCaption,
    titleImage,
    titleImageCaption,
    deviceImage,
    deviceImageCaption,
    getQualifiedText,
    signUpText,
    loginButton,
    signupButton,
    aboutText,
    videoUrls,
  } = Object.assign({}, ...data);

  if (!data.length) {
    return <LoadingSpinner />;
  }

  const dir = rtl ? "rtl" : "ltr";

  return (
    <>
      <PageHead title={`${siteTitle} :: ${pageTitle}`} />
      <Box
        pt={{
          xs: "90px",
          sm: "130px",
          md: "180px",
          lg: "220px",
          xl: "300px",
          xxl: "360px",
        }}
        pos="relative"
        textAlign="center"
        bg="primary"
        minHeight="calc(100vh - 300px)"
      >
        <Image
          pos="absolute"
          top={0}
          left={0}
          verticalAlign="middle"
          width="100%"
          src={mainImage}
          alt={mainImageCaption}
        />
        <ContentContainer pos="relative">
          <Image
            verticalAlign="middle"
            margin="0 auto"
            align="center"
            src={titleImage}
            alt={titleImageCaption}
          />
          <Box margin="0 auto" width="fit-content" pt={6}>
            <Text variant="highlight" fontSize="xl" fontFamily="Tracks Normal" dir={dir}>
              {getQualifiedText}
            </Text>
          </Box>
          <Box margin="0 auto" width="fit-content" py={24}>
            <Heading
              maxW="55rem"
              color="primaryDarker"
              fontFamily="Open Sans"
              fontWeight="semibold"
              dir={dir}
            >
              {signUpText}
            </Heading>
          </Box>
          <Image
            verticalAlign="middle"
            margin="0 auto"
            align="center"
            src={deviceImage}
            alt={deviceImageCaption}
          />
          <Flex width="fit-content" margin="0 auto" pt={14}>
            <NextLink href="auth/signup" locale={language}>
              <Link variant="signup" fontSize="lg" dir={dir}>
                {signupButton}
              </Link>
            </NextLink>
            <NextLink href="auth/login" locale={language}>
              <Link variant="login" fontSize="lg" dir={dir}>
                {loginButton}
              </Link>
            </NextLink>
          </Flex>
          <Stack direction={["row"]} py={28} justify="center" wrap="wrap">
            {videoUrls &&
              videoUrls.map((video, index) => (
                <VideoCard
                  key={index}
                  name={video.personName}
                  placeholderImage={video.placeholderImage}
                  description={video.videoDescription}
                  title={video.videoTitle}
                  url={video.url}
                  dir={dir}
                  rtl={rtl}
                />
              ))}
          </Stack>
          <Box margin="0 auto" width="fit-content" pb={28}>
            <Text variant="highlight" bg="baseLight" color="primaryDarker" fontSize="xl">
              <NextLink href="/home/about" locale={language}>
                <Link dir={dir}>{aboutText}</Link>
              </NextLink>
            </Text>
          </Box>
        </ContentContainer>
      </Box>
    </>
  );
}
